import axios from 'axios';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import jwt_decode from 'jwt-decode';
import { navigate } from 'gatsby';

export const EMAIL_REGEX_MDN = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const activeEnv = process.env.GATSBY_ACTIVE_ENV || 'local';
export const isSalonzone = activeEnv.indexOf('salon') > -1;

export const isBrowser = typeof window !== 'undefined';

export const devLog = (...args) => {
  if (
    process.env.GATSBY_ENV === 'dev' ||
    process.env.GATSBY_ENV === 'integration'
  ) {
    console.log(...args);
  }
};

export const ccyFormat = (num, currency, bankNotesAndCoins) => {
  if (currency === 'Ft') {
    return `${parseFloat(num)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} ${currency}`;
  }
  if (currency === 'GBP') {
    if (bankNotesAndCoins && parseFloat(num) < 1 && parseFloat(num) > -1) {
      return `${parseFloat(num * 100)
        .toFixed(0)
        .toString()}p`;
    }
    return `£${parseFloat(num)
      .toFixed(bankNotesAndCoins ? 0 : 2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
  if (currency === 'EUR') {
    if (bankNotesAndCoins && parseFloat(num) < 1 && parseFloat(num) > -1) {
      return `${parseFloat(num * 100)
        .toFixed(0)
        .toString()}c`;
    }
    return `€${parseFloat(num)
      .toFixed(bankNotesAndCoins ? 0 : 2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
  if (currency === 'CZK') {
    if (bankNotesAndCoins && parseFloat(num) < 1 && parseFloat(num) > -1) {
      return `${parseFloat(num * 100)
        .toFixed(0)
        .toString()}h`;
    }
    return `${parseFloat(num)
      .toFixed(bankNotesAndCoins ? 0 : 2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}Kč`;
  }
};
export const isValidEmail = (email) => EMAIL_REGEX_MDN.test(email);

export const isInt = (num) => parseInt(num, 10) === parseFloat(num);

export const isPositiveInt = (num) =>
  parseInt(num, 10) > 0 && parseInt(num, 10) === parseFloat(num);

export const isPositiveIntOrZero = (num) =>
  parseInt(num, 10) >= 0 && parseInt(num, 10) === parseFloat(num);

export const isValidDate = (d) =>
  (d && d.isValid()) || (d instanceof Date && !isNaN(d));

export const getRounding = (amount, currency) => {
  if (currency === 'Ft') {
    if (amount % 10 < 3) {
      return Math.floor(amount / 10) * 10;
    }
    if (amount >= 3 % 10 && amount % 10 <= 7) {
      return Math.floor(amount / 10) * 10 + 5;
    }
    return Math.floor(amount / 10) * 10 + 10;
  }
  return Math.round(amount * 100) / 100;
};

export const getSign = (num) => {
  if (Math.sign(num) > 0) {
    return '+';
  }
  return '';
};

export const percentageToNormal = (num) => (100 - num) / 100;

export const getUser = () =>
  (isBrowser && JSON.parse(localStorage.getItem('user'))) || null;

export const extractToken = (token) => {
  const accessDecoded = jwt_decode(token.access);
  const refreshDecoded = jwt_decode(token.refresh);
  return {
    ...token,
    accessDecoded,
    refreshDecoded,
    userId: accessDecoded.user_id,
    shopId: accessDecoded.shop_id,
    shopMap: accessDecoded.shop_map,
    shopName: accessDecoded.shop_name,
    role: accessDecoded.role,
    canFilterDate: accessDecoded.can_filter_date,
  };
};

export const updateAccessToken = (user, tokens) => {
  const accessDecoded = jwt_decode(tokens.access);
  return {
    ...user,
    access: tokens.access,
    accessDecoded,
    userId: accessDecoded.user_id,
    shopId: accessDecoded.shop_id,
    shopMap: accessDecoded.shop_map,
    shopName: accessDecoded.shop_name,
    role: accessDecoded.role,
    canFilterDate: accessDecoded.can_filter_date,
  };
};

export const getRedirectLanguage = () => {
  if (typeof navigator === 'undefined') {
    return 'en';
  }

  const lang =
    navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return 'en';

  switch (lang) {
    case 'hu':
      return 'hu';
    case 'sk':
      return 'sk';
    case 'cs':
      return 'cs';
    default:
      return 'en';
  }
};

// TODO check expiry
export const authHeader = (user) => {
  if (user && user.access) {
    return {
      Authorization: `Bearer ${user.access}`,
    };
  }
  return {};
};

export const langHeader = () => {
  const lang = window && window.location.pathname.split('/')[1];
  return (
    (lang && { 'Accept-Language': lang }) || {
      'Accept-Language': getRedirectLanguage(),
    }
  );
};

export const refreshToken = () => {
  if (!isBrowser) return false;
  devLog('Checking token expiry...');
  const user = getUser();
  if (!_.isEmpty(user)) {
    if (
      moment()
        .add(10, 'minutes')
        .isAfter(moment(user.accessDecoded.exp * 1000))
    ) {
      devLog('Token expired, request token refresh');
      return axios
        .request({
          method: 'post',
          url: `${
            isBrowser
              ? process.env.GATSBY_CSR_SERVER_DOMAIN
              : process.env.GATSBY_SSR_SERVER_DOMAIN
          }/api/token/refresh/`,
          data: {
            refresh: user.refresh,
          },
        })
        .then((response) => {
          devLog('Token refresh successful.');
          const updatedUser = updateAccessToken(user, response.data);
          localStorage.setItem('user', JSON.stringify(updatedUser));
          return Promise.resolve(updatedUser);
        })
        .catch((error) => {
          devLog('Token refresh failed.');
          Promise.reject(error);
        });
    }
    devLog(
      `${(moment(user.accessDecoded.exp * 1000) - moment()) /
        1000} seconds left.`,
    );
    return Promise.resolve(user);
  }
  return Promise.reject(null);
};

// TODO: if token expires, there's a lot of token refreshes.
// add a reducer and return Promise so everyone waits until token
// is available
export const requestBase = (method, endpoint, data, params, headers = {}) =>
  refreshToken().then((user) => {
    devLog(`Calling ${endpoint}...`);
    return axios
      .request({
        method,
        url: `${
          isBrowser
            ? process.env.GATSBY_CSR_SERVER_DOMAIN
            : process.env.GATSBY_SSR_SERVER_DOMAIN
        }${endpoint}`,
        headers: {
          ...authHeader(user),
          ...langHeader(),
          ...headers,
        },
        data,
        params,
      })
      .catch((error) => Promise.reject(error.response));
  });

export const requestAnonymBase = (
  method,
  endpoint,
  data = {},
  params = {},
  extraHeaders = {},
) => {
  devLog(`Calling ${endpoint} without auth...`);
  return axios
    .request({
      method,
      url: `${
        isBrowser
          ? process.env.GATSBY_CSR_SERVER_DOMAIN
          : process.env.GATSBY_SSR_SERVER_DOMAIN
      }${endpoint}`,
      headers: { ...langHeader(), ...extraHeaders },
      data,
      params,
    })
    .catch((error) => Promise.reject(error.response));
};

export const request = (method, url, data = {}, params = {}) => {
  devLog(`Calling ${url} without auth...`);
  return axios
    .request({
      method,
      url,
      data,
      params,
      headers: { ...langHeader() },
    })
    .catch((error) => Promise.reject(error.response));
};

export const logout = () => {
  if (!isBrowser) return false;
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  navigate('/en/app/logout');
  return Promise.resolve(null);
};

// Only calll this if error
export const handleResponseError = (response) => {
  if (response && response.status === 401) {
    logout();
    if (isBrowser) window.location.reload(true);
    // return Promise.reject(response.data.detail);
    return Promise.reject([]);
  }
  if (response && response.status >= 500) {
    return Promise.reject([response.statusText]);
  }

  if (response) {
    const data = response.data.detail ? [response.data.detail] : response.data;
    if (!Array.isArray(data)) {
      if (Object.keys(data).length > 10) {
        return Promise.reject([data]);
      }
      return Promise.reject([JSON.stringify(data)]);
    }

    return Promise.reject(data);
  }

  return Promise.reject(null);
};

export const getNet = (amount) => parseFloat(amount) / 1.27;

export const getEmploymentLabel = (item, f) => {
  switch (item.employment_kind) {
    case 'employee':
      return f({ id: 'employmentKindEmployee' });
    default:
      return f({ id: 'employmentKindContractor' });
  }
};

export const toPercentage = (val) => `${(100 * val).toFixed(0)}%`;

export const getDateFilterFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    startDate: urlParams.get('startDate'),
    endDate: urlParams.get('endDate'),
    shopIdList: urlParams.getAll('shopIdList[]').filter((x) => x !== ''),
  };
};

export const getHeroImageWidth = (rawImageUrl, windowWidth) => {
  if (!rawImageUrl) {
    return null;
  }

  if (windowWidth < 600) {
    return `https://agrddpxdbm.cloudimg.io/width/600/cffffff/${rawImageUrl}`;
  }

  if (windowWidth < 960) {
    return `https://agrddpxdbm.cloudimg.io/width/960/cffffff/${rawImageUrl}`;
  }

  if (windowWidth < 1280) {
    return `https://agrddpxdbm.cloudimg.io/width/1280/cffffff/${rawImageUrl}`;
  }

  return `https://agrddpxdbm.cloudimg.io/width/1920/cffffff/${rawImageUrl}`;
};

export const fitImage = (rawImageUrl, width, height, fillColor) =>
  `https://agrddpxdbm.cloudimg.io/fit/${width}x${height}/${fillColor}/${rawImageUrl}`;

export const colorLuminance = (hex, lum) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#';
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
};

export const weekdayKeys = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export const getWeekdayKeyFromDow = (dow, localeDow) => {
  const index = dow + localeDow;
  if (index >= 7) {
    return weekdayKeys[index - 7];
  }
  return weekdayKeys[index];
};

// https://stackoverflow.com/a/3543261
export const formatUrl = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

// https://www.ma-no.org/en/programming/javascript/javascript-arrays-immutable-functions
export const immutableSplice = (arr, start, deleteCount, ...items) => {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
};

export const changeLanguage = (langKey) => {
  navigate(
    immutableSplice(window.location.pathname.split('/'), 1, 1, langKey).join(
      '/',
    ),
  );
};

// Default 500 colors from MaterialUI
export const serviceColors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#3f51b5',
  '#2196f3',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#795548',
  '#607d8b',
];
