export default (state = [false, false, false, false], action) => {
  switch (action.type) {
    case 'NAVBAR_EXPAND_REPORTS':
      return [!state[0], false, false, false, false];
    case 'NAVBAR_EXPAND_CALENDAR':
      return [false, false, false, false, false];
    case 'NAVBAR_EXPAND_INPUTS':
      return [false, !state[1], false, false, false];
    case 'NAVBAR_EXPAND_ADMIN':
      return [false, false, !state[2], false, false];
    case 'NAVBAR_EXPAND_SALARY':
      return [false, false, false, !state[3], false];
    case 'NAVBAR_EXPAND_ADMIN_BOOKING':
      return [false, false, false, false, !state[4]];
    default:
      return state;
  }
};
