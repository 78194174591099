const user =
  typeof localStorage !== 'undefined'
    ? JSON.parse(localStorage.getItem('user'))
    : null;
const initialState = user ? { loggedIn: true, user } : null;

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        loggingIn: true,
        user: null,
      };
    case 'LOGIN_SUCCESS':
      return {
        loggedIn: true,
        user: action.user,
      };
    case 'LOGIN_FAILURE':
      return null;
    case 'LOGOUT':
      return null;
    default:
      return state;
  }
}
