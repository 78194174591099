import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { SnackbarProvider } from 'notistack';
import thunk from 'redux-thunk';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import rootReducer from './src/reducers';
import palette from './src/palette';

const configureStore = (preloadedState = {}) => {
  const windowGlobal = typeof window !== 'undefined' && window;

  let composeEnhancers = compose;

  if (windowGlobal) {
    const reduxDevTools =
      // eslint-disable-next-line
      window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

    if (reduxDevTools) {
      composeEnhancers = reduxDevTools;
    }
  }

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};

const store = configureStore();

const theme = createTheme({
  palette,
  typography: {
    useNextVariants: true,
  },
});
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Provider store={store}>{element}</Provider>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};
