/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import createHistory from 'history/createBrowserHistory';
import wrapWithProvider from './wrapWithProvider';
// import createHistory from 'history/createBrowserHistory';

// const history = createHistory();

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname);
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null);
//   //   history.block(() => {
//   //     //   const { canNavigateAway } = this.state;
//   //     //   const { purchaseItems } = this.props;
//   //     //   if (!canNavigateAway || !_.isEmpty(purchaseItems)) {
//   //     return 'A bevitt adatok törlődni fognak. Biztosan elnavigál az oldalról?';
//   //     //   }
//   //     //   return true;
//   //   });
// };

// TODO: Fix navigating away

export const wrapRootElement = wrapWithProvider;
// export const pageTransitionEvent = 'gatsby-plugin-page-transition::exit';

// const getUserConfirmation = (pathname, callback) => {
//   console.log('cccccccccc');
//   const event = new global.window.CustomEvent(pageTransitionEvent, {
//     detail: { pathname },
//   });
//   global.window.dispatchEvent(event);
//   const time = 250;
//   setTimeout(() => callback(true), time);
// };

// const history = createHistory({ getUserConfirmation });
// // history.block((location) => location.pathname);
// export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('cccccccccc');
//   const event = new global.window.CustomEvent(pageTransitionEvent, {
//     detail: { location },
//     history,
//   });
//   global.window.dispatchEvent(event);
// };

export function onServiceWorkerUpdateReady() {
  window.location.reload(true);
}
