import { isSalonzone } from './utils';

export default {
  primary: {
    light: '#484848',
    main: isSalonzone ? '#230928' : '#212121',
    dark: '#000000',
    contrastText: '#fff',
  },
  secondary: {
    light: '#fff',
    main: '#fafafa',
    dark: '#c7c7c7',
    contrastText: '#000',
  },
  danger: {
    light: '#ff7961',
    main: '#f44336',
    dark: '#ba000d',
    contrastText: '#fff',
  },
};
