const initialState = { loading: false, success: false, error: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SIGNUP_REQUEST':
      return {
        loading: true,
        success: false,
        error: null,
      };
    case 'SIGNUP_SUCCESS':
      return {
        loading: false,
        success: true,
        error: null,
      };
    case 'SIGNUP_FAILURE':
      return {
        loading: false,
        success: false,
        error: action.error,
      };
    default:
      return state;
  }
};
