import { combineReducers } from 'redux';
import authentication from './authentication';
import layoutNav from './layoutNav';
import signup from './signup';
import bookingStats from './bookingStats';

const createReducer = (initialState, handlers) => (
  state = initialState,
  action,
) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  }
  return state;
};
const returnPayload = (state, action) => action.payload || state;

const apiRequest = createReducer(
  {},
  {
    API_REQUEST_STARTED: (state, action) => ({ inProgress: true }),
    API_REQUEST_SUCCEEDED: (state, action) => ({ inProgress: false }),
    API_REQUEST_FAILED: (state, action) => ({
      inProgress: false,
      error: action.payload,
    }),
  },
);

const bookings = createReducer([], {
  LIST_BOOKINGS_SUCCESS: returnPayload,
});

const clientDetails = createReducer([], {
  GET_CLIENT_SUCCESS: returnPayload,
});

const clients = createReducer([], {
  LIST_CLIENTS_SUCCESS: returnPayload,
});

const customerSummary = createReducer([], {
  GET_CUSTOMER_SUMMARY_SUCCESS: returnPayload,
});

const dailySchedules = createReducer([], {
  LIST_DAILY_SCHEDULES_SUCCESS: returnPayload,
});

const dailyTillClose = createReducer(null, {
  GET_TILL_CLOSING_SUCCESS: returnPayload,
});

const employees = createReducer([], {
  GET_EMPLOYEES_SUCCESS: returnPayload,
});

const employeeDetails = createReducer([], {
  GET_EMPLOYEE_DETAILS_SUCCESS: returnPayload,
});

const employeeSchedules = createReducer([], {
  LIST_EMPLOYEE_SCHEDULES_SUCCESS: returnPayload,
});

const excludedBarbers = createReducer([], {
  UPDATE_EXCLUDED_BARBERS: returnPayload,
});

const inventoryInputList = createReducer([], {
  LIST_INVENTORY_INPUT_SUCCESS: returnPayload,
});

const miscCategories = createReducer([], {
  LIST_MISC_CATEGORIES_SUCCESS: returnPayload,
});

const miscSalesSummary = createReducer(null, {
  GET_MISC_SALES_SUMMARY_SUCCESS: returnPayload,
});

const miscTransactionStats = createReducer([], {
  LIST_MISC_TRANSACTION_STATS_SUCCESS: returnPayload,
});

const products = createReducer([], {
  GET_PRODUCTS_SUCCESS: returnPayload,
});

const productInventory = createReducer([], {
  GET_PRODUCT_INVENTORY_SUCCESS: returnPayload,
});

const publicShopDetails = createReducer([], {
  GET_PUBLIC_SHOP_DETAILS_SUCCESS: returnPayload,
  GET_PUBLIC_SHOP_DETAILS_FAILED: (state, action) => null,
});

const publicShopAppointments = createReducer([], {
  GET_PUBLIC_SHOP_APPOINTMENTS_SUCCESS: returnPayload,
  GET_PUBLIC_SHOP_APPOINTMENTS_FAILED: (state, action) => null,
});

const publicShopAvailabilities = createReducer([], {
  GET_PUBLIC_SHOP_AVAILABILITY_SUCCESS: returnPayload,
  GET_PUBLIC_SHOP_AVAILABILITY_FAILED: (state, action) => null,
});

const purchaseDetails = createReducer([], {
  GET_SALE_SUCCESS: returnPayload,
});

const purchaseItems = createReducer([], {
  ADD_PURCHASE_ITEM: (state, action) => [
    ...state,
    {
      id: action.payload.id,
      kind: action.payload.kind,
      itemId: action.payload.selectedItemId,
      discountRate: action.payload.discountRate,
      discountAmount: action.payload.discountAmount,
      quantity: action.payload.quantity,
      barber: action.payload.barber,
      miscSaleAmount: action.payload.miscSaleAmount,
      miscSaleComment: action.payload.miscSaleComment,
    },
  ],
  REMOVE_PURCHASE_ITEM: (state, action) =>
    state.filter((item) => item.id !== action.payload.id),
  PURCHASE_SUCCESS: (state, action) => [],
  CANCEL_PURCHASE: (state, action) => [],
});

const purchaseTotal = createReducer([], {
  SET_PURCHASE_PREVIEW_TOTAL: returnPayload,
});

const revenueSummary = createReducer([], {
  GET_REVENUE_SUMMARY_SUCCESS: returnPayload,
});
const reviewsApproved = createReducer([], {
  LIST_REVIEWS_APPROVED_SUCCESS: returnPayload,
});
const reviewsDenied = createReducer([], {
  LIST_REVIEWS_DENIED_SUCCESS: returnPayload,
});
const reviewsUnprocessed = createReducer([], {
  LIST_REVIEWS_UNPROCESSED_SUCCESS: returnPayload,
});

const salaryAssignments = createReducer([], {
  LIST_SALARY_ASSIGNMENTS_SUCCESS: returnPayload,
});

const salaryCalculations = createReducer([], {
  CALCULATE_SALARY_SUCCESS: returnPayload,
});

const salaryDeductions = createReducer([], {
  LIST_SALARY_DEDUCTIONS_SUCCESS: returnPayload,
});

const salaryRule = createReducer([], {
  GET_SALARY_RULE_SUCCESS: returnPayload,
  RESET_SALARY_RULE: (state, action) => ({}),
});

const salaryRules = createReducer([], {
  LIST_SALARY_RULES_SUCCESS: returnPayload,
});

const salesSummary = createReducer([], {
  GET_SALES_SUMMARY_SUCCESS: returnPayload,
});

const schedules = createReducer([], {
  LIST_SCHEDULES_SUCCESS: returnPayload,
});

const sales = createReducer([], {
  LIST_SALES_SUCCESS: returnPayload,
});

const settings = createReducer(null, {
  GET_SETTINGS_SUCCESS: returnPayload,
  GET_SETTINGS_REQUEST: (state, action) => ({ ...state, ...action.payload }),
});

const onboarding = createReducer(null, {
  GET_ONBOARDING_SUCCESS: returnPayload,
  GET_ONBOARDING_REQUEST: (state, action) => ({ ...state, ...action.payload }),
});

const services = createReducer([], {
  GET_SERVICES_SUCCESS: returnPayload,
});

const tillClosings = createReducer(null, {
  LIST_TILL_CLOSINGS_SUCCESS: returnPayload,
  RESET_TILL_CLOSING_LIST: (state, action) => [],
});

const usageDetails = createReducer(
  {
    product: {},
    created_by: { display_name: null },
  },
  {
    GET_USAGE_SUCCESS: returnPayload,
  },
);

const usageStats = createReducer([], {
  LIST_USAGE_STATS_SUCCESS: returnPayload,
});

const usageSummary = createReducer([], {
  GET_USAGE_SUMMARY_SUCCESS: returnPayload,
});

const usages = createReducer([], {
  LIST_USAGE_SUCCESS: returnPayload,
});

const tipSummary = createReducer([], {
  GET_TIP_SUMMARY_SUCCESS: returnPayload,
});

const productServicePerformance = createReducer([], {
  GET_PRODUCTSERVICE_PERFORMANCE_SUCCESS: returnPayload,
});

const serviceSalesPerformance = createReducer([], {
  GET_SERVICE_SALES_PERFORMANCE_SUCCESS: returnPayload,
});

const productSalesPerformance = createReducer([], {
  GET_PRODUCT_SALES_PERFORMANCE_SUCCESS: returnPayload,
});

const performanceStats = createReducer([], {
  GET_PERFORMANCE_STATS_SUCCESS: returnPayload,
});

const tillShortage = createReducer([], {
  GET_PERFORMANCE_TILL_SHORTAGE_SUCCESS: returnPayload,
});

export default combineReducers({
  purchaseItems,
  services,
  products,
  employees,
  purchaseDetails,
  sales,
  tillClosings,
  dailyTillClose,
  purchaseTotal,
  authentication,
  employeeDetails,
  usages,
  usageDetails,
  usageStats,
  revenueSummary,
  customerSummary,
  salesSummary,
  miscSalesSummary,
  usageSummary,
  tipSummary,
  productInventory,
  inventoryInputList,
  salaryRules,
  salaryAssignments,
  salaryCalculations,
  salaryRule,
  settings,
  publicShopDetails,
  publicShopAppointments,
  bookings,
  schedules,
  layoutNav,
  apiRequest,
  clients,
  employeeSchedules,
  dailySchedules,
  excludedBarbers,
  clientDetails,
  salaryDeductions,
  signup,
  bookingStats,
  publicShopAvailabilities,
  miscCategories,
  miscTransactionStats,
  reviewsApproved,
  reviewsDenied,
  reviewsUnprocessed,
  productServicePerformance,
  serviceSalesPerformance,
  productSalesPerformance,
  performanceStats,
  tillShortage,
  onboarding,
});
