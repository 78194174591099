const INIT_STATE = {
  kpi: {},
  timeseries: [],
  services: [],
  barbers: [],
  bookings: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_BOOKING_STATS_KPI':
      return { ...state, kpi: action.payload };
    case 'GET_BOOKING_STATS_TIMESERIES':
      return { ...state, timeseries: action.payload };
    case 'GET_BOOKING_STATS_SERVICES':
      return { ...state, services: action.payload };
    case 'GET_BOOKING_STATS_BARBERS':
      return { ...state, barbers: action.payload };
    case 'GET_BOOKING_STATS_BOOKINGS':
      return { ...state, bookings: action.payload };
    default:
      return state;
  }
};
